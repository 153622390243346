import request from '_utils/request'


const get_product = () => {
  return request({
    url: '/api/product/getTimeLimitProduct',
    method: 'GET'
  })
}

export {
  get_product
}
