<template>
  <div class="special">
    <div class="title">
      <div>恭喜您！</div>
      <div>您已成功通过{{ topProduct.name }}的初审</div>
    </div>
    <p>预估总额度：50000元</p>
    <main>
      <div class="item">
        <span>借款额度：</span>
        <input type="number" @input="coverInput" v-model.mumber="coverLoanMoney">
        <em>元</em>
      </div>
      <div class="item" @click="coverShowDatePicker = true">
        <span>借款期限：</span>
        <p>{{ loanTime }}<i>个月</i></p>
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/special/arrow-right.png"
            alt="">
      </div>
      <div class="container">
        <div>
          <div>{{ parseInt(coverLoanMoney * 0.011 * loanTime) }}<span>元</span></div>
          <p>预估利息</p>
        </div>
        <div>
          <div>{{ parseInt((coverLoanMoney * 0.011 * loanTime + ~~coverLoanMoney) / loanTime) }}<span>元</span></div>
          <p>月还款</p>
        </div>
        <div>
          <div>1.1<span>%</span></div>
          <p>月利率</p>
        </div>
      </div>
    </main>
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/special/steps.png"
        alt=""
    />
    <img
        @click="jump"
        style="margin: 0;"
        v-throttle="2000"
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/special/submit-button.png"
        alt=""
    />
    <div class="cover-bottom-content" @click="$router.push('/superLoan')">查看更多产品</div>
    <van-popup position="bottom" v-model="coverShowDatePicker">
      <van-picker
          title="标题"
          show-toolbar
          :columns="[3, 6, 9, 12]"
          @confirm="(params) => {
            coverShowDatePicker = false
            loanTime = params
          }"
          @cancel="coverShowDatePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  click_product_item,
  get_product_list
} from '_network/superLoan'
import { get_product } from '_network/special'
import { to_apply_super_loan_list_product } from '_network/product'

export default {
  data() {
    return {
      coverLoanMoney: 50000,
      loanTime: 12,
      coverShowDatePicker: false,
      topProduct: {}
    }
  },
  methods: {
    coverInput() {
      if (this.coverLoanMoney > 50000) {
        this.$toast({
          type: 'fail',
          message: '已达到最大借款额度'
        })
      }
      this.coverLoanMoney = this.coverLoanMoney > 50000 ? 50000 : this.coverLoanMoney
      this.coverLoanMoney = this.coverLoanMoney < 10 ? 10 : this.coverLoanMoney
    },
    async getProductList() {
      try {
        const { data } = await get_product_list()
        this.topProduct = (data || []).splice(0, 1)[0] || {}
      } catch (e) {

      }
    },
    jump() {
      localStorage.setItem('specialSubmit', 1)
      this.toApply(this.topProduct)
    },
    //获取推荐产品
    async getProduct() {
      try {
        const {data} = await get_product()
        if (!data || !data.length) {
          await this.getProductList()
          return
        }
        let index = Math.floor(Math.random() * data.length)
        const previousProductId = localStorage.getItem('previousProduct')
        while (previousProductId === data[index].id.toString()) {
          index = Math.floor(Math.random() * data.length)
        }
        localStorage.setItem('previousProduct', data[index].id)
        this.topProduct = data[index]
      } catch (e) {
        console.log(e)
      }
    },
    async toApply(product) {
      if (!product.id && product.id !== 0) {
        this.$toast({
          type: 'fail',
          message: '暂无产品'
        })
        return
      }
      try {
        //需要先添加1次产品的点击次数
        await click_product_item(product.id)
        if (product.type === 1) {
          location.href = product.h5Url
        } else {
          //这里是联登产品
          if (product.middlePageSwitch) {
            localStorage.setItem("productItem", JSON.stringify(product))
            await this.$router.push("/middlepage")
          } else {
            const { data } = await to_apply_super_loan_list_product(product.id)
            if (data.state) {
              this.$toast({
                type: 'fail',
                message: '申请失败'
              })
            } else {
              location.href = data.downUrl
            }
          }
        }
      } catch (err) {
      }
    }
  },
  created() {
    if (localStorage.getItem('specialSubmit')) {
      this.$router.replace('/superLoan')
    }
    this.getProduct()
  }
}
</script>

<style scoped lang="less">
.special {
  width: 100vw;
  min-height: 100vh;
  padding: 0.48rem 0.533rem;
  background: #fff;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
  }

  & > p {
    margin: 0.32rem 0 0.533rem;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
  }

  main {
    padding: 0;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 1.28rem;
      margin-bottom: 0.267rem;
      padding: 0 0.4rem;
      border-radius: 0.64rem;
      border: 1px solid #E9EAF6;
      line-height: 1;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #2E2F31;
      }

      input,
      p {
        display: block;
        flex: 1;
        max-width: 5.333rem;
        border: 0;
        font-size: 24px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #2E2F31;

        i {
          font-size: 14px;
          font-weight: 400;
        }
      }

      em {
        font-size: 14px;
        color: #8E8F93;
      }

      img {
        width: 16px;
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.693rem 0.453rem 0.373rem;
      background: #F8FAFF;
      font-size: 16px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #2E2F31;
      text-align: center;
      border-radius: 0.213rem;

      p {
        margin-top: 0.24rem;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8E8F93;
      }

      span {
        font-size: 14px;
      }
    }
  }

  & > img {
    width: 100%;
    margin: 0.533rem 0 1.067rem;
  }

  .cover-bottom-content {
    margin-top: 0.533rem;
    font-size: 16px;
    text-align: center;
    color: #999999;
  }
}
</style>
